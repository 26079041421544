<template>
  <div>
    <!-- 个人信息凭证 -->
    <van-cell>
      <template slot="title">
        <barcode
          :value="patientid"
          :options="barcode_option"
          tag="canvas"
          style="width: 100%"
        ></barcode>
      </template>
      <template slot="label">
        <div style="text-align: center">
          <span>姓名：{{ userName }}</span>
          <span style="margin-left: 30px">门诊号：{{ patientid }}</span>
        </div>
        <div style="text-align:center;">身份证：{{idCard}}</div>
      </template>
    </van-cell>
    <!-- 提示 -->
    <van-cell>
      <template slot="title">
        <!-- <span style="color:red;">*福贡县人民医院温馨提示：微信医院挂号可在微信医院上进行退号退费，其他渠道挂号的请到收费窗口进行处理</span> -->
        <!-- <span style="color:red;">*江城县人民医院温馨提示：微信医院挂号可在微信医院上进行退号退费，其他渠道挂号的请到收费窗口进行处理</span> -->
        <span style="color: red; font-size: 12px"
          >*福贡县人民医院温馨提示：仅能查询3个月内的记录，如需查询更早以前请到窗口查询。(默认查询7天，可在下方更改时段)</span
        >
      </template>
    </van-cell>
    <!-- 时间搓 -->
    <div class="regilist_center">
      <van-cell-group>
        <template slot="title">
          <div style="text-align: center">
            <span style="float: left; color: black">日期选择：</span>
            <span @click="showTime = true">{{ date }}</span>
            <!-- <van-calendar v-model="showTime" type="range" @confirm="onConfirm" :selected="startdate"  :min-date="minDate" :max-date="maxDate"/> -->
            <van-calendar
              v-model="showTime"
              type="range"
              @confirm="onConfirm"
              :defaultDate="defaultDate"
              :min-date="minDate"
              :max-date="maxDate"
              color="#57c4b7"
            />
            <!-- <van-calendar v-model="showTime" type="range" /> -->
          </div>
        </template>
      </van-cell-group>
    </div>
    <!-- 订单列表 -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      style="padding: 0 5px"
    >
      <van-cell
        v-for="(item, index) in dataList"
        :key="index"
        style="margin-top: 15px; border-radius: 10px"
      >
        <template slot="title">
          <div style="text-align: center">
            {{ item.drName }}
          </div>
        </template>
        <!-- 内容 -->
        <template slot="label">
          <!-- 一行 -->
          <van-cell style="padding: 5px 0 0 0; font-size: 12px">
            <template slot="title">
              就诊人：<span style="color: #969799">{{ userName }}</span>
            </template>
            <template slot="default">
              <span style="color: black">门诊号：</span>
              <span>{{ patientid }}</span>
            </template>
          </van-cell>
          <!-- 二行 -->
          <van-cell style="padding: 0; font-size: 12px">
            <template slot="title">
              处方号：<span style="color: #969799">{{
                item.recipes[0].recipeNO
              }}</span>
            </template>
            <template slot="default" style="color: black">
              <span style="color: black">挂号流号：</span>{{ item.regNo }}
            </template>
          </van-cell>
          <!-- 三行 -->
          <van-cell style="padding: 0; font-size: 12px">
            <template slot="title">
              处方金额：<span style="color: orange">{{ item.totalFee }}元</span>
            </template>
            <template slot="default">
              <span style="color: black">支付状态：</span>：
              <span v-if="item.payStatus === 1" style="color: green"
                >已经支付</span
              ><span v-else-if="item.payStatus === 2" style="color: orange"
                >已退款</span
              ><span v-else style="color: red">未支付</span>
            </template>
          </van-cell>
          <van-cell style="padding: 0; font-size: 12px">
            <template slot="title">
              开方时间：<span style="color: #969799">{{ item.operTime }} </span>
            </template>
          </van-cell>
          <div style="height: 15px; backgroun-color: #f8f8f8"></div>
          <!-- 隐藏的明细 -->
          <van-cell
            :id="item.recipes[0].recipeNO"
            style="display: none; padding: 0; font-size: 12px"
            title="处方明细:"
          >
            <template slot="label">
              <div
                v-for="(el, i) in item.recipes[0].clinicDetails"
                :key="i"
                style="background-color: #f8f8f8; margin-top: 10px"
              >
                <!-- 隐藏第一行 -->
                <van-cell style="padding: 0; font-size: 12px; margin-top: 10px">
                  <template slot="title">
                    项目名称：
                    <span>{{ el.itemName }}</span></template
                  >
                </van-cell>
                <!-- 隐藏第二行 -->
                <van-cell style="padding: 0; font-size: 12px">
                  <template slot="title">
                    费用类别：<span>{{ el.cate }}</span>
                  </template>
                </van-cell>
                <!-- 隐藏第三行 -->
                <van-cell style="padding: 0; font-size: 12px">
                  <template slot="title">
                    单价*数量：<span
                      >￥{{ el.itemPrice }}x{{ el.itemNum }}{{ el.unit }}</span
                    >
                  </template>
                </van-cell>
                <!-- 隐藏四 -->
                <van-cell style="padding: 0; font-size: 12px">
                  <template slot="title">
                    规格：<span>{{ el.specs }} </span>
                  </template>
                </van-cell>
                <!-- 隐藏五 -->
                <van-cell style="padding: 0; font-size: 12px">
                  <template slot="title">
                    执行科室：<span>{{ item.deptName }} </span>
                  </template>
                </van-cell>
                <hr />
              </div>
            </template>
          </van-cell>
          <!-- 下拉 -->
          <!-- <div style="height: 5px; backgroun-color: #f8f8f8"></div> -->
          <van-cell style="padding: 2px 0">
            <template slot="title">
              <div
                style="text-align: center; border-top: 1px solid #f8f8f8"
                @click="showOrhisb"
                :data-showOrhisb="item.recipes[0].recipeNO"
                :id="item.recipes[0].recipeNO + '010'"
              >
                更多详情
              </div>
            </template>
          </van-cell>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import ajax from "../../lib/ajax";
// import jsbarcode from "../../lib/JsBarcode.all.min.js";
// import store from "../../store";
export default {
  data() {
    return {
      idCard:"",
      isShow: false,
      dataList: [], //显示的数据列表
      loading: false, //加载状态
      finished: true, //加载状态文字
      showTime: false, //时间选择器
      date: "",
      defaultDate: [],
      minDate: new Date(new Date().setDate(new Date().getDate() - 83)),
      maxDate: new Date(new Date().setDate(new Date().getDate() + 7)),
      startTime: "",
      endTime: "",
      userName: "", //用户名
      patientid: "", //病历号
      barcode_option: { format: "CODE128", width: 2, height: 30,fontSize:12, },
    };
  },
  created() {
    this.userName=this.$route.query.userName;
     this.patientid = this.$route.query.patientid ; //声明病例号
     this.idCard = this.$route.query.idc ;
    // http://localhost:8080/cCFL?userName="ygl"&patientid="0001829468"
    console.log(this.userName, this.patientid );
    // this.userName = store.getters["store/userName"];
    // this.patientid = store.getters["store/patientid"]; //声明病例号
    // console.log(this.userName);
    // console.log(this.patientid);
    //初始化时间
    const lastDate = new Date(new Date().setDate(new Date().getDate() + 7));
    const date = [new Date(), lastDate];

    this.defaultDate = date;

    // 初始化数据
    this.onConfirm(date);
  },
  mounted() {
    // this.getData();
    // jsbarcode("#bar", this.patientid, {
    //   displayValue: true, // 是否在条形码下方显示文字
    //   format: "CODE39", //选择要使用的条形码类型
    //   width: 2, //设置条之间的宽度
    //   height: 60, //高度
    //   text: this.patientid,
    // });
  },
  methods: {
    getData() {
      // 数据清空
      // console.log("开始时间：" + this.startTime);
      // console.log("结束时间：" + this.endTime);
      ajax
        .post("/Api/FeeService/GetFeeLog", {
          userID: this.patientid,
          beginDate: this.startTime + " 00:00:00",
          // beginDate: "2021-08-01" + " 00:00:00",
          endDate: this.endTime + " 23:59:59",
        })
        .then((res) => {
          // console.log(res);
          let dataList = res.data.result.clinicFees;
          console.log(dataList);
          dataList.forEach((el) => {
            if (el.drName == "核酸自助开单") {
              this.dataList.push(el);
            }
          });
          console.log(this.dataList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 张开或收起
    showOrhisb(e) {
      let tarData = e.target.dataset.showorhisb;
      let el = document.getElementById(tarData);
      if (el.style.display == "block") {
        el.style.display = "none";
        document.getElementById(`${tarData}010`).innerText = "更多详情";
      } else {
        el.style.display = "block";
        document.getElementById(`${tarData}010`).innerText = "收起";
      }

      // console.log(el.style.display);
    },
    //切换时间格式
    formatDate(date) {
      return `${
        date.getMonth() >= 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }${date.getDate() >= 10 ? "-" + date.getDate() : "-0" + date.getDate()}`;
    },
    //日历改变事件
    onConfirm(date) {
      // console.log(date);
      const [start, end] = date;
      this.showTime = false;
      this.startTime =
        `${start.getFullYear()}` + "-" + `${this.formatDate(start)}`;
      this.endTime = `${end.getFullYear()}` + "-" + `${this.formatDate(end)}`;
      this.date = `${
        start.getMonth() >= 9
          ? start.getMonth() + 1
          : "0" + (start.getMonth() + 1)
      }${
        start.getDate() >= 10 ? "-" + start.getDate() : "-0" + start.getDate()
      }日至${
        end.getMonth() >= 9 ? end.getMonth() + 1 : "0" + (end.getMonth() + 1)
      }${end.getDate() >= 10 ? "-" + end.getDate() : "-0" + end.getDate()}日`;
      // this.sortDrop(this.value2);
      // return [start,end];
      this.getData();
    },
  },
};
</script>
<style>
</style>
